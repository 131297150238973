import cn from "classnames";

export const NAV_ID_PREFIX = "nav";

type SidebarItemProps = {
  id: string;
  text: string;
  isActive: boolean;
  href: string;
  shouldIndent?: boolean;
};

const SidebarItem = ({
  id,
  text,
  href,
  isActive,
  shouldIndent = false,
}: SidebarItemProps) => {
  const handleClick = () => {
    window.location.replace(href);
  };

  return (
    <div
      id={`${NAV_ID_PREFIX}-${id}`}
      onClick={handleClick}
      className={cn(
        shouldIndent ? "pl-8" : "pl-4",
        isActive
          ? "bg-nav-sel-bg rounded-r text-nav-text-selected"
          : "hover:text-nav-text-hovered text-nav-text",
        "text-sm pr-4 mr-2 py-2 font-medium font-stripe cursor-pointer"
      )}
    >
      {text}
    </div>
  );
};

export default SidebarItem;
