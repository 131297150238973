import * as Sentry from "@sentry/react";
import { sec } from "api/api";
import { ReactNode, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import AuthErrorFallback from "./error-handling/AuthErrorFallback";
export default function ConfigurationWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const { user, error, isLoading } = useAuth();

  // Store method for be accessed by functions outside of AuthProvider
  sec.setAccessTokenSilently(() => {
    return Promise.resolve(user ? user.access_token : "");
  });

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        email: user.profile.email,
        id: user.profile.sub,
        username: user.profile.name,
      });
    }
    Sentry.configureScope((scope) => {
      if (user) {
        scope.setTag("user_id", user.profile.sub);
      }
    });
  }, [user]);

  if (!isLoading && error) {
    Sentry.captureException(error);
    return <AuthErrorFallback error={error} />;
  }

  return <>{children}</>;
}
