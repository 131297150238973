import { configureScope, startTransaction } from "@sentry/react";
import { Span, SpanContext, TransactionContext } from "@sentry/types";

interface CustomTransaction {
  setStatus: (status: string) => void;
  finish: () => void;
  startChild: (spanContext?: SpanContext) => Span | undefined;
}

export function startOrGetTransaction(
  context: TransactionContext
): CustomTransaction {
  const transaction = startTransaction(context);
  configureScope((scope) => {
    scope.setSpan(transaction);
  });

  const finish = () => {
    transaction.finish();
  };

  const setStatus = (status: string) => {
    transaction.setStatus(status);
  };

  const startChild = (spanContext?: SpanContext): Span | undefined => {
    return transaction.startChild(spanContext);
  };

  return {
    finish,
    setStatus,
    startChild,
  };
}

export const TelemetryStatus = {
  /** The operation was aborted, typically due to a concurrency issue. */
  Aborted: "aborted",
  /** Already exists (409) */
  AlreadyExists: "already_exists",
  /** The operation was cancelled (typically by the user). */
  Cancelled: "cancelled",
  /** Unrecoverable data loss or corruption */
  DataLoss: "data_loss",
  /** Deadline expired before operation could complete. */
  DeadlineExceeded: "deadline_exceeded",
  /** Operation was rejected because the system is not in a state required for the operation's */
  FailedPrecondition: "failed_precondition",
  /** Other/generic 5xx. */
  InternalError: "internal_error",
  /** Client specified an invalid argument. 4xx. */
  InvalidArgument: "invalid_argument",
  /** 404 Not Found. Some requested entity (file or directory) was not found. */
  NotFound: "not_found",
  /** The operation completed successfully. */
  Ok: "ok",
  /** Operation was attempted past the valid range. */
  OutOfRange: "out_of_range",
  /** 403 Forbidden */
  PermissionDenied: "permission_denied",
  /** 429 Too Many Requests */
  ResourceExhausted: "resource_exhausted",
  /** 401 Unauthorized (actually does mean unauthenticated according to RFC 7235) */
  Unauthenticated: "unauthenticated",
  /** 503 Service Unavailable */
  Unavailable: "unavailable",
  /** 501 Not Implemented */
  Unimplemented: "unimplemented",
  /** Unknown. Any non-standard HTTP status code. */
  UnknownError: "unknown_error",
};
