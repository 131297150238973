import { ExternalAdditionalPage } from "components/zupidoc/ZupiDoc";
import { BASE_URL } from "utils/env";
import {
  getHrefForUserGeneratedPage,
  UserGeneratedPageConfig,
} from "utils/userGeneratedContent";
import Header from "./Header";

type HeaderWithNavProps = {
  pages: UserGeneratedPageConfig[];
  activeID: string;
};

const HeaderWithNav = ({ pages, activeID }: HeaderWithNavProps) => {
  const handleNavSelectionChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const url = e.target.value;
    window.location.replace(url);
  };
  // TODO: @AdrianMachado handle internal additional pages
  const externalAdditionalPages = pages.filter(
    (page): page is ExternalAdditionalPage => "url" in page
  );
  return (
    <div className="w-full bg-primary-background z-20 sticky top-0 border-b-2 items-center flex lg:z-1 lg:relative lg:border-b-0 py-2">
      <div className="pl-3">
        <select
          value={getHrefForUserGeneratedPage(activeID)}
          onChange={handleNavSelectionChange}
          className="lg:hidden w-fit bg-primary-background"
        >
          {externalAdditionalPages.map(({ id, title }) => {
            return (
              <option value={getHrefForUserGeneratedPage(id)} key={id}>
                {title}
              </option>
            );
          })}
          <option value={BASE_URL}>API Overview</option>
        </select>
      </div>
      <Header />
    </div>
  );
};

export default HeaderWithNav;
