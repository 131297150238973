import { ExclamationCircleIcon } from "@heroicons/react/outline";

type AuthErrorFallbackProps = {
  error: Error;
};
/**
 * @description Error fallback for Authentication errors
 */
const AuthErrorFallback = ({ error }: AuthErrorFallbackProps) => {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col justify-center items-start border border-gray-400 rounded-md py-4 px-6 shadow-md">
        <div className="flex flex-col justify-center max-w-7xl mb-5">
          <div className="inline-flex items-center text-2xl text-pink-600 font-bold">
            <ExclamationCircleIcon className="w-10 h-10 text-pink-600" />
            &nbsp;"Authorization Error. Check the configuration of your
            authorization provider.
          </div>
          <div className="text-pink-600 p-4 w-96">
            Error:&nbsp;{error.toString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthErrorFallback;
