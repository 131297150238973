import * as Sentry from "@sentry/react";
import { API_URL } from "utils/env";
import { TelemetryStatus } from "utils/telemetry";
import { v4 } from "uuid";

// TODO: This implementation is a maintenance issue, needs to be cleaned up.
// Global variable for access getAccessToken function outside of AuthProvider
let getAccessTokenSilently: (() => Promise<string>) | null = null;
export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: (() => Promise<string>) | null) =>
    (getAccessTokenSilently = func),
};

export async function apiFetch(
  path: string,
  init?: RequestInit
): Promise<Response> {
  const traceId = v4();
  const token = await sec.getAccessTokenSilently()?.();
  if (!token) {
    throw new Error(`Token is not available`);
  }
  const telemetryPath = path;
  const httpMethod = init?.method ?? "GET";

  const transaction = Sentry.getCurrentHub().getScope()?.getTransaction();
  if (!transaction && process.env.NODE_ENV !== "production") {
    // We want to surface this warning to developers without throwing as
    // Adblock will also cause a transaction to be undefined
    // eslint-disable-next-line no-console
    console.warn(`Telemetry not enabled for Management API`, telemetryPath);
  }

  const span = transaction?.startChild({
    data: { path },
    description: `${httpMethod} ${telemetryPath}`,
    op: "http.client",
    tags: {
      trace_id: traceId,
    },
  });
  const traceHeaders = Sentry.getCurrentHub().traceHeaders();

  const options = { ...init };
  const headers = {
    Authorization: `Bearer ${token}`,
    ...init?.headers,
    "zuplo-request-id": traceId,
    ...traceHeaders,
  };
  if (!options.headers) {
    options.headers = {};
  }
  Object.assign(options.headers, headers);

  /**
   * API_DOMAIN could be defined or not, if we are on dev environment we define with a value
   * if we are on production environment we get the portal domain
   */
  const result = await fetch(
    new URL(`${API_URL ?? window.location.origin}${path}`).href,
    options
  );
  span?.setHttpStatus(result.status);

  if (result.ok) {
    span?.setStatus(TelemetryStatus.Ok);
  }
  span?.finish();

  return result;
}
