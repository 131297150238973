import cn from "classnames";

interface ButtonProps {
  testID?: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export default function Button({
  testID,
  type = "button",
  disabled = false,
  children,
  className = "",
  onClick,
}: ButtonProps) {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      data-test={testID}
      disabled={disabled}
      onClick={onClick}
      className={cn(
        "inline-block text-sm px-4 py-2 leading-none border rounded border-gray-400",
        {
          "cursor-not-allowed opacity-50": disabled,
          "hover:border-transparent hover:text-gray-600 hover:bg-gray-200":
            !disabled,
        },
        className
      )}
    >
      {children}
    </button>
  );
}
