export const baseTheme = {
  backgroundNavItemSelected: "#F5FBFF",
  backgroundPrimary: "white",
  textColorH1: "rgb(42 47 69)",
  textColorH2: "rgb(42 47 69)",
  textColorH3: "rgb(42 47 69)",
  textColorNav: "#697386",
  textColorNavHovered: "#2A2F45",
  textColorNavSelected: "#566CD6",
  textColorPrimary: "rgb(105 115 134)",
};
