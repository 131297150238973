import Button from "components/common/Button";
import { useAuth } from "react-oidc-context";

const LoginButton = () => {
  const { signinRedirect } = useAuth();
  return (
    <Button
      testID="login-button"
      className="py-3 px-6 bg-gray-200 text-gray-700 hover:bg-gray-400 border-0"
      onClick={signinRedirect}
    >
      Sign in
    </Button>
  );
};

export default LoginButton;
