import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { MANAGEMENT_API_URL } from "utils/env";
import { mgmtApiFetch } from "./mgmt-api-utils";

export async function getFeatureFlags(): Promise<{
  featureFlags: LDFlagSet[];
  privateAttributes: { [key: string]: string[] };
}> {
  const response = await mgmtApiFetch(
    `${MANAGEMENT_API_URL}/v1/feature-flags`,

    {
      method: "GET",
    }
  );
  return response.json();
}
