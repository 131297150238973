import { apiFetch } from "api/api";
import { startOrGetTransaction, TelemetryStatus } from "utils/telemetry";

/**
 * @param comments Main body of feedback
 * @param email Email of bug reporter
 * @param eventID Sentry Event ID of the error
 * @param name Name of the bug reporter
 * @description Central API for reporting user feedback to Sentry.
 * Docs: https://docs.sentry.io/api/projects/submit-user-feedback/
 */
export async function submitUserFeedback(
  comments: string,
  email: string,
  eventID: string,
  name: string
): Promise<void> {
  const transaction = startOrGetTransaction({
    name: "Submit user feedback to sentry",
  });
  try {
    const response = await apiFetch(`/sentry/feedback`, {
      body: JSON.stringify({
        comments,
        email,
        eventID,
        name,
        project: "dev-portal",
      }),
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
    });

    if (response.status !== 200) {
      transaction.setStatus(TelemetryStatus.InternalError);
      throw new Error("Error sending feedback");
    }
  } finally {
    transaction.finish();
  }
}
