import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { FallbackRender } from "@sentry/react/types/errorboundary";
import React from "react";
import { HelpContactForm } from "./HelpContactForm";

/**
 * @description This Fallback component is the top level handler of errors and
 * should have few dependencies + little functionality to ensure it can't
 * throw its own exceptions
 */
export const simpleErrorFallback: FallbackRender = ({ error, eventId }) => (
  <React.Fragment>
    <div className="fixed 2xl:absolute z-30 flex flex-col w-full h-full justify-center items-center bg-white">
      <div className="flex flex-col justify-center items-start border border-gray-400 rounded-md py-4 px-6 shadow-md">
        <div className="flex flex-col justify-center max-w-7xl mb-5">
          <div className="inline-flex items-center text-2xl text-pink-600 font-bold">
            <ExclamationCircleIcon className="w-10 h-10 text-pink-600" />
            &nbsp;Something went wrong.
          </div>
          <div className="text-pink-600 p-4 w-96 h-96 max-h-96 break-words overflow-y-scroll">
            {error.toString()}
          </div>
        </div>
        <div className="">
          {eventId && <HelpContactForm eventID={eventId} />}
        </div>
      </div>
    </div>
  </React.Fragment>
);
