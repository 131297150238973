export interface Theme {
  [key: string]: string;
}

export interface Themes {
  [key: string]: Theme;
}

export interface MappedTheme {
  [key: string]: string | null;
}

export const mapTheme = (variables: Theme): MappedTheme => {
  return {
    "--background-nav": variables.backgroundNav || "",
    "--background-nav-item-selected": variables.backgroundNavItemSelected || "",
    "--background-primary": variables.backgroundPrimary || "",
    "--text-color-h1": variables.textColorH1 || "",
    "--text-color-h2": variables.textColorH2 || "",
    "--text-color-h3": variables.textColorH3 || "",
    "--text-color-nav": variables.textColorNav || "",
    "--text-color-nav-hovered": variables.textColorNavHovered || "",
    "--text-color-nav-selected": variables.textColorNavSelected || "",
    "--text-color-primary": variables.textColorPrimary || "",
  };
};

export const applyTheme = (theme: Theme): void => {
  const themeObject: MappedTheme = mapTheme(theme);
  // if (!themeObject) return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    if (property === "name") {
      return;
    }

    root.style.setProperty(property, themeObject[property]);
  });
};

export const extend = (extending: Theme, newTheme: Theme): Theme => {
  return { ...extending, ...newTheme };
};
