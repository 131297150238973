import { getFeatureFlags } from "api/feature-flags-api";
import { initialize, LDClient } from "launchdarkly-js-client-sdk";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAuth } from "react-oidc-context";
import { LAUNCH_DARKLY_CLIENT_IDE } from "utils/env";

interface FeatureFlagsContextValue {
  flagInstance: LDClient | undefined;
  initFeatureFlagsClient: (() => Promise<void>) | undefined;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextValue>({
  flagInstance: undefined,
  initFeatureFlagsClient: undefined,
});

export const FeatureFlagsProvider = ({
  children,
}: PropsWithChildren<Record<string, unknown>>) => {
  const [flagInstance, setFlagInstance] = useState<LDClient>();
  const { user } = useAuth();

  const subscribedTo = useCallback(
    <T,>(featureFlagKey: string, cta: (value: T, previous: T) => void) => {
      if (flagInstance) {
        flagInstance.on(`change:${featureFlagKey}`, (value, previous) => {
          cta(value, previous);
        });
      } else {
        throw Error("Flag instance is not initialized");
      }
    },
    [flagInstance]
  );

  const isEnabled = useCallback(
    (featureFlagKey: string) => {
      if (flagInstance) {
        return flagInstance.variation(featureFlagKey);
      } else {
        throw Error("Flag instance is not initialized");
      }
    },
    [flagInstance]
  );

  const initFlags = useCallback(() => {
    if (flagInstance) {
      // setYourVariable(isEnabled("FLAG_NAME"));
      // subscribedTo<boolean>("FLAG_NAME", (value, previous) => {
      //   setYourVariable(value);
      // });
    } else {
      throw Error("Flag instance is not initialized");
    }
  }, [flagInstance]);

  useEffect(() => {
    if (flagInstance) {
      initFlags();
    }
  }, [flagInstance, initFlags, isEnabled, subscribedTo]);

  const initFeatureFlagsClientWithUser = useCallback(async () => {
    if (user && flagInstance) {
      try {
        const bootstrapFeatureFlags = await getFeatureFlags();
        await flagInstance.identify({
          custom: {
            ...bootstrapFeatureFlags.privateAttributes,
          },
          email: user.profile.email,
          key: user.profile.sub,
          privateAttributeNames: ["accountNames"],
        });
      } catch (error) {
        await flagInstance.identify({
          email: user.profile.email,
          key: user.profile.sub,
          privateAttributeNames: ["accountNames"],
        });
      }
    }
  }, [flagInstance, user]);

  const initFeatureFlagsClient = useCallback(async () => {
    const flagsClient = initialize(
      LAUNCH_DARKLY_CLIENT_IDE,
      {
        anonymous: true,
      },
      { streaming: true }
    );
    await flagsClient.waitForInitialization();
    setFlagInstance(flagsClient);
  }, []);

  useEffect(() => {
    void initFeatureFlagsClient();
  }, [initFeatureFlagsClient]);

  useEffect(() => {
    if (user && flagInstance) {
      void initFeatureFlagsClientWithUser();
    }
  }, [initFeatureFlagsClientWithUser, user, flagInstance]);

  const value = {
    flagInstance,
    initFeatureFlagsClient,
  };

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
