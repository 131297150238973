import * as Sentry from "@sentry/react";
import { API_URL, ASSET_URL, BASE_URL, BUILD_ID, OPEN_API_URL } from "./env";

type SidebarItem = { id: string; type: "category" | "doc"; label: string };
export type Sidebar = Record<string, SidebarItem[] | undefined>;
export type UserGeneratedPageConfig = {
  id: string;
  url: string;
  title: string;
};

export const getUserGeneratedPages = async (): Promise<
  UserGeneratedPageConfig[] | undefined
> => {
  let buildId = BUILD_ID;
  if (!buildId) {
    // Backward compatibility for before we sent the build_id in config
    const baseURL = API_URL
      ? new URL(API_URL)
      : `https://${new URL(OPEN_API_URL ?? "").hostname}`;
    const buildURL = `${baseURL}/__zuplo/build`;
    const buildResult = await fetch(buildURL);
    if (!buildResult.ok) {
      Sentry.captureException(
        new Error(`Failed to fetch build from url ${buildURL}`)
      );
      return;
    }
    const buildResultRaw = await buildResult.json();
    buildId = buildResultRaw.build_id;
    if (typeof buildId !== "string") {
      throw new Error("Invalid buildId retrieved from server");
    }
  }

  const sidebarURL = `${ASSET_URL}/${buildId}/docs/sidebar.json`;
  const sidebarResult = await fetch(sidebarURL);
  if (!sidebarResult.ok) {
    Sentry.captureException(
      new Error(`Failed to fetch sidebar file from url ${sidebarURL}`)
    );
    return;
  }
  const sidebarFile = (await sidebarResult.json()) as Sidebar;
  const pages = sidebarFile.docs
    ?.map(({ id, type, label }: SidebarItem) => {
      if (type === "doc") {
        const docURL = `${ASSET_URL}/${buildId}/docs/${id}.html`;
        return { id, title: label, url: docURL };
      }
      // TODO: ZUP-1274 @AdrianMachado Supported nested folder structure
      return null;
    })
    .filter(
      (
        userGeneratedPageConfig: UserGeneratedPageConfig | null
      ): userGeneratedPageConfig is UserGeneratedPageConfig =>
        userGeneratedPageConfig != null
    );
  return pages;
};

export const getHrefForUserGeneratedPage = (id: string) => {
  return `${BASE_URL}${BASE_URL ? "" : "/"}${id}`;
};
