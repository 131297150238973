import { baseTheme } from "./base";
import { darkTheme } from "./dark";
import { Themes } from "./utils";

/**
 * The default theme to load
 */
export const DEFAULT_THEME = "base";

export const themes: Themes = {
  base: baseTheme,
  dark: darkTheme,
};
