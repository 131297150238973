export default function LoadingIcon({
  className = "h-20 w-auto",
}: {
  className: string;
}) {
  return (
    <img
      className={className}
      src={`${process.env.PUBLIC_URL}/loading.svg`}
      alt="Loading"
    />
  );
}
