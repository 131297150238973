import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import LoadingIcon from "../components/layout/Loading";
import {
  AUTH_AUTHORITY,
  AUTH_CLIENT_ID,
  AUTH_PROVIDER,
  BASE_URL,
} from "../utils/env";

export default function LogoutPage() {
  const { removeUser, signoutRedirect } = useAuth();
  useEffect(() => {
    const returnTo = `${window.location.origin}${BASE_URL}`;
    if (AUTH_PROVIDER === "auth0" && AUTH_CLIENT_ID) {
      // Auth0 doesn't impliment standard OIDC redirect URL so we
      // need to build the logout url manually and redirect
      void removeUser().then(() => {
        if (!AUTH_CLIENT_ID) {
          throw new Error("AUTH_CLIENT_ID is not defined");
        }
        const url = new URL(AUTH_AUTHORITY);
        url.pathname = "/v2/logout";
        url.searchParams.set("client_id", AUTH_CLIENT_ID);
        url.searchParams.set("returnTo", returnTo);
        window.location.replace(url.toString());
      });
    } else {
      void signoutRedirect({
        post_logout_redirect_uri: returnTo,
      });
    }
  }, [removeUser, signoutRedirect]);

  return <LoadingIcon className="h-40 inline mx-auto w-full" />;
}
