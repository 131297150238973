import * as Sentry from "@sentry/react";
import { MANAGEMENT_API_URL } from "utils/env";
import { TelemetryStatus } from "utils/telemetry";
import { v4 } from "uuid";

interface ApiPath {
  path: string;
  telemetryPath: string;
}

// Global variable for access getAccessToken function outside of AuthProvider
let getAccessTokenSilently: (() => Promise<string>) | null = null;
export const sec = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: (() => Promise<string>) | null) =>
    (getAccessTokenSilently = func),
};

export async function mgmtApiFetch(
  apiPath: ApiPath | string,
  init?: RequestInit
): Promise<Response> {
  const traceId = v4();
  let realPath: string;
  let telemetryPath: string;
  if (typeof apiPath === "string") {
    realPath = apiPath;
    telemetryPath = apiPath;
  } else {
    realPath = apiPath.path;
    telemetryPath = apiPath.telemetryPath;
  }
  const token = await sec.getAccessTokenSilently()?.();
  if (!token) {
    throw new Error(`Token is not available`);
  }
  const httpMethod = init?.method ?? "GET";

  const url = new URL(`${MANAGEMENT_API_URL}/v1${realPath}`);
  const transaction = Sentry.getCurrentHub().getScope()?.getTransaction();
  if (!transaction && process.env.NODE_ENV !== "production") {
    // We want to surface this warning to developers without throwing as
    // Adblock will also cause a transaction to be undefined
    // eslint-disable-next-line no-console
    console.warn(`Telemetry not enabled for Management API`, telemetryPath);
  }

  const span = transaction?.startChild({
    data: { apiPath },
    description: `${httpMethod} ${telemetryPath}`,
    op: "http.client",
    tags: {
      trace_id: traceId,
    },
  });
  const traceHeaders = Sentry.getCurrentHub().traceHeaders();

  const options = { ...init };
  const headers = {
    Authorization: `Bearer ${token}`,
    "zuplo-request-id": traceId,
    ...traceHeaders,
  };
  if (!options.headers) {
    options.headers = {};
  }

  Object.assign(options.headers, headers);

  const result = await fetch(url.toString(), options);
  span?.setHttpStatus(result.status);
  if (!result.ok) {
    span?.finish();
    try {
      const text = await result.text(); // Parse it as text
      if (!text) {
        throw Error();
      }
      const data = JSON.parse(text); // Try to parse it as JSON
      throw data;
    } catch (err) {
      if (typeof err === "object" && err.message) {
        throw new Error(err.message);
      }

      throw new Error(
        `Encountered ${result.status}: ${result.statusText} while making request to ${realPath}`
      );
    }
  } else {
    span?.setStatus(TelemetryStatus.Ok);
    span?.finish();
  }

  return result;
}
