import { submitUserFeedback } from "api/third-party/sentry-api";
import React, { useState } from "react";
import { useAuth } from "react-oidc-context";
import Button from "../Button";

interface HelpContactFormProps {
  eventID: string;
}

export const HelpContactForm = ({ eventID }: HelpContactFormProps) => {
  const { user } = useAuth();

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const name = user?.profile.name || "No Name";
  const [email, setEmail] = useState(user?.profile.email ?? "");
  const [comment, setComment] = useState("");
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [hasSubmittedFeedback, setHasSubmittedFeedback] = useState(false);
  const handleEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
  };
  const handleCommentChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(evt.target.value);
  };
  const handleUserFeedbackSubmit = async (
    evt: React.ChangeEvent<HTMLFormElement>
  ) => {
    evt.preventDefault(); // Prevent default submission
    setIsSubmittingFeedback(true);

    await submitUserFeedback(
      comment || "No comments provided",
      email,
      eventID,
      name
    );
    setIsSubmittingFeedback(false);
    setHasSubmittedFeedback(true);
  };

  if (hasSubmittedFeedback) {
    return (
      <div className="flex flex-row w-full justify-items-center items-center">
        <div className="flex flex-row font-bold text-xl pl-2">
          Feedback submitted - help is on the way!
        </div>
      </div>
    );
  }
  return (
    <form
      className="flex flex-col w-full justify-items-start items-start"
      onSubmit={handleUserFeedbackSubmit}
    >
      <div className="flex flex-row font-bold text-xl">
        Help us fix this for you
      </div>
      <div className="flex flex-row mt-4 w-96">
        <div className="mr-4 text-lg">Email:</div>
        <input
          className="text-md shadow-sm flex-grow h-8 border border-gray-400 pl-1"
          disabled={isSubmittingFeedback}
          type="email"
          value={email}
          autoComplete="email"
          required={true}
          placeholder="your@email.com"
          onChange={handleEmailChange}
        />
      </div>
      <div className="flex flex-col mt-4 w-96">
        <div className="text-lg">What happened?</div>
        <textarea
          className="text-md mt-2 shadow-sm flex-grow h-32 border border-gray-400 pl-1"
          disabled={isSubmittingFeedback}
          value={comment}
          placeholder="What you were doing or what went wrong"
          onInput={handleCommentChange}
        />
      </div>
      <div className="flex flex-col mt-4 w-96">
        <Button
          disabled={isSubmittingFeedback}
          type="submit"
          className="px-4 py-2 text-base self-end"
        >
          {isSubmittingFeedback ? "" : "Send"}
        </Button>
      </div>
    </form>
  );
};
