import React from "react";
import { Theme } from "themes/utils";
import { LIGHT_THEME } from "utils/env";

export const ThemeContext = React.createContext<
  [Theme, (theme: Theme) => void]
>([
  LIGHT_THEME,
  () => {
    return;
  },
]);
