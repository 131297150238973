import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Bootstrap from "Bootstrap";
import React from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {
  FAVICON_URL,
  PAGE_TITLE,
  SENTRY_DSN,
  SENTRY_ENV,
  SENTRY_RELEASE,
} from "utils/env";

if (SENTRY_DSN) {
  Sentry.init({
    autoSessionTracking: true,
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    // TODO: @AdrianMachado use react-router-v6 integration once 7.0.0 is
    // released: https://github.com/getsentry/sentry-javascript/releases
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
        traceFetch: false,
        traceXHR: true,
        tracingOrigins: [
          "localhost",
          "api.zuplo.com",
          "api-staging.zuplo.com",
          /zuplo\.dev/g,
        ],
      }),
    ],
    release: SENTRY_RELEASE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
  });
}
if (PAGE_TITLE) {
  document.title = PAGE_TITLE;
}

const faviconLink = document.querySelector(
  "link[rel*='icon']"
) as HTMLLinkElement;
faviconLink.href = FAVICON_URL;
const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);
const ProfiledApp = Sentry.withProfiler(Bootstrap);

root.render(<ProfiledApp />);
