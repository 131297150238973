import { DEFAULT_THEME, themes } from "themes";
import { DEFAULT_DARK_THEME } from "themes/dark";
import { Theme } from "themes/utils";

interface DevPortalTheme extends Theme {
  backgroundNavItemSelected: string;
  backgroundPrimary: string;
  textColorH1: string;
  textColorH2: string;
  textColorH3: string;
  textColorNav: string;
  textColorNavHovered: string;
  textColorNavSelected: string;
  textColorPrimary: string;
}
declare global {
  interface Window {
    config?: {
      auth_audience: string;
      /**
       * The full url of the auth server, i.e. https://account.us.auth0.com
       */
      auth_authority: string;
      /**
       * The auth provider (i.e. auth0, okta)
       */
      auth_provider: string;
      auth_configured_on_routes: boolean;
      /**
       * @deprecated Use `auth_authority` instead
       */
      auth_domain: string; // backward compatibility
      /**
       * The client ID of the auth app
       */
      auth_client_id: string;
      base_url: string;
      build_id: string;
      /**
       * @deprecated Use `auth_client_id` instead
       */
      client_id: string;
      favicon_url: string;
      logo_url: string;
      open_api_url: string;
      page_title: string;
      version_configs: { name: string; url: string }[];
      light_theme?: DevPortalTheme;
      dark_theme?: DevPortalTheme;
    };
  }
}

export const ENVIRONMENT =
  window.config == null
    ? "LOCAL"
    : window.location.host.endsWith("zuplo.dev")
    ? "DEV"
    : "PROD";

// Configured by core
export const AUTH_PROVIDER = window.config?.auth_provider ?? "auth0";
export const AUTH_AUDIENCE =
  window.config?.auth_audience ?? process.env.REACT_APP_AUTH_AUDIENCE;
export const AUTH_AUTHORITY =
  window.config?.auth_authority ??
  `https://${
    window.config?.auth_domain ?? process.env.REACT_APP_AUTH_AUTHORITY
  }`;
export const AUTH_CLIENT_ID =
  window.config?.auth_client_id ??
  window.config?.client_id ??
  process.env.REACT_APP_AUTH_CLIENT_ID;
export const BASE_URL = window.config?.base_url ?? "/";
export const OPEN_API_URL =
  window.config?.open_api_url ?? process.env.REACT_APP_OPEN_API_URL;
export const OPEN_API_VERSION_CONFIGS: { name: string; url: string }[] =
  window.config?.version_configs ??
  JSON.parse(process.env.REACT_APP_OPEN_API_VERSION_CONFIGS ?? "[]");
export const BUILD_ID = window.config?.build_id ?? process.env.BUILD_ID;
export const LOGO_URL =
  window.config?.logo_url ?? `${process.env.PUBLIC_URL}/made-with-zuplo.svg`;
const faviconFallback =
  ENVIRONMENT !== "PROD"
    ? `${process.env.PUBLIC_URL}/favicon-dev.ico`
    : `${process.env.PUBLIC_URL}/favicon.ico`;
export const FAVICON_URL = window.config?.favicon_url ?? faviconFallback;
export const PAGE_TITLE = window.config?.page_title;
export const IS_AUTH_CONFIGURED_ON_ROUTES =
  window.config?.auth_configured_on_routes ?? true;
export const LIGHT_THEME = window.config?.light_theme ?? themes[DEFAULT_THEME];
export const DARK_THEME =
  window.config?.dark_theme ?? themes[DEFAULT_DARK_THEME];

// Misc
export const LAUNCH_DARKLY_CLIENT_IDE =
  ENVIRONMENT !== "LOCAL"
    ? "61b9f9970e42590f6e801af7"
    : "61b9f9970e42590f6e801af6";
export const MANAGEMENT_API_URL = process.env.REACT_APP_MANAGEMENT_API_URL;
export const API_URL =
  window.location.port === "4101"
    ? process.env.REACT_APP_TEST_API_URL
    : process.env.REACT_APP_API_URL ?? undefined;

// Sentry stuff
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN ?? undefined;
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV ?? "local";
export const SENTRY_RELEASE = process.env.CF_PAGES_COMMIT_SHA;
export const SENTRY_ORGANIZATION = "zuplo";
export const SENTRY_PROJECT = "dev-portal";

// Asset CDN stuff
export const DEFAULT_ASSET_URL = `https://customer-assets-${
  ENVIRONMENT !== "LOCAL" ? "production" : "staging"
}.zuplo.dev`;
export const ASSET_URL =
  window.location.port === "4101"
    ? process.env.REACT_APP_TEST_ASSET_URL
    : process.env.REACT_APP_ASSET_URL ?? DEFAULT_ASSET_URL;
