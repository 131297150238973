import { ExternalAdditionalPage } from "components/zupidoc/ZupiDoc";
import { BASE_URL } from "utils/env";
import {
  getHrefForUserGeneratedPage,
  UserGeneratedPageConfig,
} from "utils/userGeneratedContent";

import SidebarItem from "./SidebarItem";

type SidebarProps = {
  pages: UserGeneratedPageConfig[];
  iconURL: string;
};

const Sidebar = ({ pages, iconURL }: SidebarProps) => {
  // TODO: @AdrianMachado handle internal additional pages
  const externalAdditionalPages = pages.filter(
    (page): page is ExternalAdditionalPage => "url" in page
  );
  return (
    <div className="hidden lg:inline w-1/5 border-r">
      <div className="h-full flex flex-col">
        <img alt="logo" className="flex-none max-w-[150px] m-3" src={iconURL} />
        <div className="flex-auto overflow-y-auto">
          <div className="flex flex-col">
            {externalAdditionalPages.map(({ id, title }) => {
              const href = getHrefForUserGeneratedPage(id);
              return (
                <SidebarItem
                  key={id}
                  id={id}
                  shouldIndent={false}
                  text={title}
                  isActive={window.location.pathname === href}
                  href={href}
                />
              );
            })}
            <SidebarItem
              id="API Overview"
              text="API Overview"
              shouldIndent={false}
              isActive={false}
              href={BASE_URL}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
