import { Menu } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/outline";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

const UserIcon = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <Menu as="div">
      <Menu.Button
        data-test="user-icon"
        className="p-1 rounded-full hover:bg-gray-200"
      >
        {!user || !user.profile.picture ? (
          <UserCircleIcon className="h-9 w-9 lg:h-10 lg:w-10" />
        ) : (
          <img
            className="block mx-auto h-9 w-9 lg:h-10 lg:w-10 rounded-full"
            src={user.profile.picture}
            referrerPolicy="no-referrer"
            alt=""
          />
        )}
      </Menu.Button>
      <Menu.Items className="origin-bottom-left absolute right-0 w-auto bg-white divide-y divide-gray-100 rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mr-9">
        <Menu.Item>
          <button
            type="button"
            data-test="logout-button"
            className="p-2 w-full text-right px-4 hover:bg-indigo-100"
            onClick={() => {
              navigate(`__logout`);
            }}
          >
            Logout
          </button>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export default UserIcon;
